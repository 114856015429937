import request from '@/utils/request'
const verificationUrl = {
  verify: '/insurance/insurance/verify',
  getDetail: '/insurance/insurance/search', // 查询保单详情
  insuranceClaimsConfig: '/insurance/claimsItems/insuranceClaimsConfig', // 通过保单获取理赔配置项
  claimsApplySave: '/insurance/claimsApply/save', // 理赔申请提交
  claimsApplyCancel: '/insurance/claimsApply/cancel', // 理赔取消
  claimsApplyFind: 'insurance/claimsApply/findByInsuranceNo', // 理赔详情
  claimsApplySaveOpenId: '/insurance/claimsApply/saveOpenId', // 保存openid
  customerInsurance: '/insurance/insurance/customerInsurance', // 查询客户保单信息
  viewVoucher: '/insurance/insurance/voucher', // 获取保单文件
}
/**
 * 获取验证码
 * @param {*} parameter
 */
export function verify(parameter) {
  return request.post(verificationUrl.verify, parameter)
}

export function getDetail(parameter) {
  return request.post(verificationUrl.getDetail, parameter)
}
export function insuranceClaimsConfig(parameter) {
  return request.get(verificationUrl.insuranceClaimsConfig, parameter)
}
export function claimsApplySave(parameter) {
  return request.post(verificationUrl.claimsApplySave, parameter, {
    'Content-Type': "application/json;charset=utf-8"
  })
}
export function claimsApplyCancel(parameter) {
  return request.post(verificationUrl.claimsApplyCancel, parameter)
}
export function claimsApplyFind(parameter) {
  return request.get(verificationUrl.claimsApplyFind, parameter)
}
export function claimsApplySaveOpenId(parameter) {
  return request.get(verificationUrl.claimsApplySaveOpenId, parameter)
}
export function customerInsurance(parameter) {
  return request.get(verificationUrl.customerInsurance, parameter)
}
export function viewVoucher(parameter) {
  return request.get(verificationUrl.viewVoucher, parameter)
}
